
















































































































































































import BaseComponent from '@/core/base.component';
import { Component } from 'vue-property-decorator';

@Component({
  name: 'AboutUs',
})
export default class AboutUs extends BaseComponent {
  navbarHeight: any = 0;
  private swiperOption: any = {
    slidesPerView: 1,
    loop: true,
    grabCursor: false,
    allowTouchMove: true,
    speed: 700,
    autoplay: {
      delay: 5000,
    },
    breakpoints: {
      1200: {
        loop: false,
        slidesPerView: 3,
        spaceBetween: 20,
      },
    },
  };

  mounted() {
    const target = document.querySelector('.navbar');
    this.navbarHeight = target.getBoundingClientRect().height;
  }
}
